/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "palette";
@import "utility";

:root {
  .ios {
    --wic-border-radius: 8px;
  }
  .md {
    --wic-border-radius: 4px;
  }
}

//Banners
.ios wic-home {
  --home-button-radius: 16px;
}
.md wic-home {
  --home-button-radius: 8px;
}

swiper-container.banner-slides {
  div.swiper-wrapper{
    align-items: center;
  }
  div.swiper-pagination-bullets.swiper-pagination {
    bottom: 15px;
  }
}

.map {
  border-top: solid 1px #ccc;
  width: 100%;
  height: 225px;
  ngui-map {
    height: 100%;
  }
}

.full-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(3px);
}

.toast-success {
  --background: var(--light-green);
  --color: var(--white);
  --button-color: var(--white);
}

.toast-warning {
  --background: var(--yellow-tint);
  --color: var(--white);
  --button-color: var(--white);
}

.toast-error {
  --background: var(--moderate-red);
  --color: var(--white);
  --button-color: var(--white);
}

.modal-error {
  --background: var(--moderate-red);
  --color: var(--white);
  --button-color: var(--white);
  --border-radius: var(--wic-border-radius, 6px);
  --height: 80vh;
  --width: 90vw;
}

.ios.popover-centered {
  --offset-x: calc(calc(calc(100vw - min(max(var(--width), var(--min-width)), var(--max-width))) / 2) - 5px);
}

.popover-benefit-error {
  --max-width: 400px;
  --min-width: 300px;
  --width: 85vw;
}

.alert-error {
  --ion-color-primary: var(--moderate-red);
  --background: var(--white);
}

ion-toolbar {
  --min-height: 8vh;
}

@media screen and (orientation:landscape) {
  ion-toolbar {
    --min-height: 14vh;
  }
}

ion-popover.new-shopping-list-entry,
ion-popover.add-shopping-list-cvv-item,
ion-popover.add-shopping-list-item-from,
ion-popover.warn-item-not-carried,
ion-popover.add-shopping-list-item {
  --backdrop-opacity: 0.3;
  --width: 80vw;
  //--height: 25vh;
}

.permissions-popover {
  --ion-backdrop-opacity: 0.35;

  &::part(content) {
    --width: 300px;
    --max-width: 350px;
    --offset-y: -25vh;
  }
}

.no-wic-popover {
  --ion-backdrop-opacity: 0.35;

  &::part(content) {
    --width: 300px;
    --max-width: 350px;
  }
}

.btn-tufts-blue {
  --background: var(--tufts-blue);
  --background-activated: var(--tufts-blue-tint);
  --background-focused: var(--tufts-blue-shade);
  --background-hover: var(--tufts-blue);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}

.btn-green {
  --background: var(--green);
  --background-activated: var(--green-tint);
  --background-focused: var(--green-shade);
  --background-hover: var(--green);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}

.btn-deep-spring-bud {
  --background: var(--deep-spring-bud);
  --background-activated: var(--deep-spring-bud-tint);
  --background-focused: var(--deep-spring-bud-tint);
  --background-hover: var(--deep-spring-bud);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}

.btn-yellow {
  --background: var(--yellow);
  --background-activated: var(--yellow-tint);
  --background-focused: var(--yellow-shade);
  --background-hover: var(--yellow);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}


.btn-orange {
  --background: var(--orange);
  --background-activated: var(--orange-tint);
  --background-focused: var(--orange-shade);
  --background-hover: var(--orange);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}

.btn-vivid-orange-peel {
  --background: var(--vivid-orange-peel);
  --background-activated: var(--vivid-orange-peel-tint);
  --background-focused: var(--vivid-orange-peel-shade);
  --background-hover: var(--vivid-orange-peel);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}

.btn-maroon {
  --background: var(--dark-red);
  --background-activated: var(--dark-red-tint);
  --background-focused: var(--dark-red-shade);
  --background-hover: var(--dark-red);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}


.btn-independence {
  --background: var(--independence);
  --background-activated: var(--independence-tint);
  --background-focused: var(--independence-shade);
  --background-hover: var(--independence);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}


.btn-imperial {
  --background: var(--imperial);
  --background-activated: var(--imperial-tint);
  --background-focused: var(--imperial-shade);
  --background-hover: var(--imperial);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}

.btn-misty-moss {
  --background: var(--misty-moss);
  --background-activated: var(--misty-moss-tint);
  --background-focused: var(--misty-moss-shade);
  --background-hover: var(--misty-moss);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}

.btn-deep-spring-bud {
  --background: var(--deep-spring-bud);
  --background-activated: var(--deep-spring-bud-tint);
  --background-focused: var(--deep-spring-bud-shade);
  --background-hover: var(--deep-spring-bud);
  --color: var(--white);
  --color-activated: var(--white);
  --color-focused: var(--white);
  --color-hover: var(--white);
}




.cal {
  svg {
    width: 15vw;

    path:nth-child(15) {
      fill: red;
    }
  }
}

.cal-none {
  svg {
    path {
      fill: grey;
    }
  }
}

.cal-0 {
  svg {
    path:nth-child(15) {
      fill: orange;
    }
  }
}

.cal-1 {
  svg {
    path:nth-child(14) {
      fill: orange;
    }
  }
}

.cal-5 {
  svg {
    path:nth-child(12) {
      fill: orange;
    }
  }
}

.cal-7 {
  svg {
    path:nth-child(10) {
      fill: orange;
    }
  }
}

.cal-14 {
  svg {
    path:nth-child(5) {
      fill: orange;
    }
  }
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-text-color-step-800, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
}

ion-alert.no-benefit-alert {
  text-align: center;
  --height: 150px;
  --width: 200px;
}