/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

$lava: #ff4000;
$lava-tint: tint($lava, 20%);
$lava-shade: shade($lava, 20%);

$red: #ff0000;
$red-tint: tint($red, 20%);
$red-shade: shade($red, 20%);

$moderate-red: #ff5550;
$moderate-red-tint: tint($moderate-red, 20%);
$moderate-red-shade: shade($moderate-red, 20%);

$dark-red: #8b0000;
$dark-red-tint: tint($dark-red, 20%);
$dark-red-shade: shade($dark-red, 20%);

$darker-red: #5c0000;
$darker-red-tint: tint($darker-red, 20%);
$darker-red-shade: shade($darker-red, 20%);

$soft-red: #ff8680;
$light-red: #ffccc0;

$orange: #ff8000;
$orange-tint: tint($orange, 20%);
$orange-shade: shade($orange, 20%);

$soft-orange: #ffbf80;
$gold: #fcb61e;
$soft-gold: #e7bb6a;
$yellow: #cccc00;
$yellow-tint: tint($yellow, 20%);
$yellow-shade: shade($yellow, 20%);

$green: #008000;
$green-tint: tint($green, 20%);
$green-shade: shade($green, 20%);

$blue: #4169e1;

$dark-blue: #00008b;

$tufts-blue: #418ac1;
$tufts-blue-tint: tint($tufts-blue, 20%);
$tufts-blue-shade: shade($tufts-blue, 20%);

$light-blue: #add8e6;
$light-grey: #d3d3d3;
$med-grey: #808080;
$dark-grey: #636363;
$field-grey: #999;

$burgundy: #710627;
$burgundy-tint: tint($burgundy, 20%);
$burgundy-shade: shade($burgundy, 20%);

$dark-green: #005534;
$dark-green-tint: tint($dark-green, 20%);
$dark-green-shade: shade($dark-green, 20%);

$vivid-orange-peel: #f0a202;
$vivid-orange-peel-tint: tint($vivid-orange-peel, 20%);
$vivid-orange-peel-shade: shade($vivid-orange-peel, 20%);

$deep-spring-bud: #60712f;
$deep-spring-bud-tint: tint($deep-spring-bud, 20%);
$deep-spring-bud-shade: shade($deep-spring-bud, 20%);

$independence: #454372;
$independence-tint: tint($independence, 20%);
$independence-shade: shade($independence, 20%);

$misty-moss: #bcab79;
$misty-moss-tint: tint($misty-moss, 20%);
$misty-moss-shade: shade($misty-moss, 20%);

$teal-blue: #33658a;
$teal-blue-tint: tint($teal-blue, 20%);
$teal-blue-shade: shade($teal-blue, 20%);

$imperial: #55286f;
$imperial-tint: tint($imperial, 20%);
$imperial-shade: shade($imperial, 20%);

$big-foot-feet: #ea8c55;
$big-foot-feet-tint: tint($big-foot-feet, 20%);
$big-foot-feet-tint: shade($big-foot-feet, 20%);

$light-spring-bud: #b5bc69;
$light-spring-bud-tint: tint($light-spring-bud, 20%);
$light-spring-bud-shade: shade($light-spring-bud, 20%);

$deep-taupe: #774c60;
$deep-taupe-tint: tint($deep-taupe, 20%);
$deep-taupe-shade: shade($deep-taupe, 20%);

$purple-mountain-majesty: #a475a5;
$purple-mountain-majesty-tint: tint($purple-mountain-majesty, 20%);
$purple-mountain-majesty-shade: shade($purple-mountain-majesty, 20%);

$prussian-blue: #0b2954;
$prussian-blue-tint: tint($prussian-blue, 20%);
$prussian-blue-shade: shade($prussian-blue, 20%);

:root {
  --lava: #ff4000;
  --lava-tint: #{$lava-tint};
  --lava-shade: #{$lava-shade};
  --red: #ff0000;
  --red-tint: #{$red-tint};
  --red-shade: #{$red-shade};
  --moderate-red: #cc3330;
  --dark-red: #8b0000;
  --dark-red-tint: #{$dark-red-tint};
  --dark-red-shade: #{$dark-red-shade};
  --darker-red: #5c0000;
  --darker-red-tint: #{$darker-red-tint};
  --darker-red-shade: #{$darker-red-shade};
  --soft-red: #ff8680;
  --soft-light-red: #ffc6c0;
  --light-red: #ffefee;
  --orange: #ff8000;
  --orange-tint: #{$orange-tint};
  --orange-shade: #{$orange-shade};
  --soft-orange: #ffbf80;
  --gold: #fcb61e;
  --soft-gold: #e7bb6a;
  --yellow: #ccc200;
  --yellow-tint: #{$yellow-tint};
  --yellow-shade: #{$yellow-shade};
  --dark-yellow: #665c00;
  --light-yellow: #f5e79f;
  --green: #008000;
  --green-tint: #{$green-tint};
  --green-shade: #{$green-shade};
  --light-green: #7acc7a;
  --blue: #4169e1;
  --dark-blue: #00008b;
  --tufts-blue: #418ac1;
  --tufts-blue-tint: #{$tufts-blue-tint};
  --tufts-blue-shade: #{$tufts-blue-shade};
  --light-blue: #add8e6;
  --light-grey: #d3d3d3;
  --med-grey: #808080;
  --dark-grey: #636363;
  --field-grey: #999;
  --off-white: #f9f9f9;
  --white: #fff;
  --black: #000;

  --burgundy: #710627;
  --burgundy-tint: #{$burgundy-tint};
  --burgundy-shade: #{$burgundy-shade};
  --dark-green: #005534;
  --dark-green-tint: #($dark-green-tint);
  --dark-green-shade: #($dark-green-shade);
  --vivid-orange-peel: #f0a202;
  --vivid-orange-peel-tint: #{$vivid-orange-peel-tint};
  --vivid-orange-peel-shade: #{$vivid-orange-peel-shade};
  --deep-spring-bud: #60712f;
  --deep-spring-bud-tint: #{$deep-spring-bud-tint};
  --deep-spring-bud-shade: #{$deep-spring-bud-shade};
  --independence: #454372;
  --independence-tint: #{$independence-tint};
  --independence-shade: #{$independence-shade};
  --misty-moss: #bcab79;
  --misty-moss-tint: #{$misty-moss-tint};
  --misty-moss-shade: #{$misty-moss-shade};
  --teal-blue: #33658a;
  --imperial: #55286f;
  --imperial-tint: #{$imperial-tint};
  --imperial-shade: #{$imperial-shade};
  --big-foot-feet: #ea8c55;
  --light-spring-bud: #b5bc69;
  --light-spring-bud-tint: #{$light-spring-bud-tint};
  --light-spring-bud-shade: #{$light-spring-bud-shade};
  --deep-taupe: #774c60;
  --purple-mountain-majesty: #a475a5;
  --prussian-blue: #0b2954;

  --ion-color-independence: #454372;
  --ion-color-independence-rgb: 69, 67, 114;
  --ion-color-independence-contrast: #ffffff;
  --ion-color-independence-contrast-rgb: 255, 255, 255;
  --ion-color-independence-shade: #3d3b64;
  --ion-color-independence-tint: #585680;

  --ion-color-blue: #4169e1;
  --ion-color-blue-rgb: 65, 105, 225;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #395cc6;
  --ion-color-blue-tint: #5478e4;

  --ion-color-tufts-blue: #418ac1;
  --ion-color-tufts-blue-rgb: 65, 138, 193;
  --ion-color-tufts-blue-contrast: #ffffff;
  --ion-color-tufts-blue-contrast-rgb: 255, 255, 255;
  --ion-color-tufts-blue-shade: #3979aa;
  --ion-color-tufts-blue-tint: #5496c7;

  --ion-color-tufts-blue-shaded: #3979aa;
  --ion-color-tufts-blue-shaded-rgb: 57, 121, 170;
  --ion-color-tufts-blue-shaded-contrast: #ffffff;
  --ion-color-tufts-blue-shaded-contrast-rgb: 255, 255, 255;
  --ion-color-tufts-blue-shaded-shade: #326a96;
  --ion-color-tufts-blue-shaded-tint: #4d86b3;

  --ion-color-vivid-orange-peel: #f0a202;
  --ion-color-vivid-orange-peel-rgb: 240, 162, 2;
  --ion-color-vivid-orange-peel-contrast: #ffffff;
  --ion-color-vivid-orange-peel-contrast-rgb: 255, 255, 255;
  --ion-color-vivid-orange-peel-shade: #d38f02;
  --ion-color-vivid-orange-peel-tint: #f2ab1b;

  --ion-color-orange: #ff8000;
  --ion-color-orange-rgb: 255, 128, 0;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #e07100;
  --ion-color-orange-tint: #ff8d1a;

  --ion-color-lava: #ff4000;
  --ion-color-lava-rgb: 255, 64, 0;
  --ion-color-lava-contrast: #ffffff;
  --ion-color-lava-contrast-rgb: 255, 255, 255;
  --ion-color-lava-shade: #e03800;
  --ion-color-lava-tint: #ff531a;

  --ion-color-burgundy: #710627;
  --ion-color-burgundy-rgb: 113, 6, 39;
  --ion-color-burgundy-contrast: #ffffff;
  --ion-color-burgundy-contrast-rgb: 255, 255, 255;
  --ion-color-burgundy-shade: #630522;
  --ion-color-burgundy-tint: #7f1f3d;

  --ion-color-dark-green: #005534;
  --ion-color-dark-green-rgb: 0, 85, 52;
  --ion-color-dark-green-contrast: #ffffff;
  --ion-color-dark-green-contrast-rgb: 255, 255, 255;
  --ion-color-dark-green-shade: #004b2e;
  --ion-color-dark-green-tint: #1a6648;

  --ion-color-dark-red: #8b0000;
  --ion-color-dark-red-rgb: 139, 0, 0;
  --ion-color-dark-red-contrast: #ffffff;
  --ion-color-dark-red-contrast-rgb: 255, 255, 255;
  --ion-color-dark-red-shade: #7a0000;
  --ion-color-dark-red-tint: #971a1a;

  --ion-color-soft-red: #ff8680;
  --ion-color-soft-red-rgb: 255, 134, 128;
  --ion-color-soft-red-contrast: #ffffff;
  --ion-color-soft-red-contrast-rgb: 255, 255, 255;
  --ion-color-soft-red-shade: #e07671;
  --ion-color-soft-red-tint: #ff928d;

  --ion-color-yellow: #cccc00;
  --ion-color-yellow-rgb: 204, 204, 0;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-yellow-shade: #b4b400;
  --ion-color-yellow-tint: #d1d11a;

  --ion-color-bright-yellow: #f0f078;
  --ion-color-bright-yellow-rgb: 240, 240, 120;
  --ion-color-bright-yellow-contrast: #000000;
  --ion-color-bright-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-bright-yellow-shade: #d3d36a;
  --ion-color-bright-yellow-tint: #f2f286;

  --ion-color-light-yellow: #f5e79f;
  --ion-color-light-yellow-rgb: 245, 231, 159;
  --ion-color-light-yellow-contrast: #ffffff;
  --ion-color-light-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-light-yellow-shade: #d8cb8c;
  --ion-color-light-yellow-tint: #f6e9a9;

  --ion-color-gold: #fcb61e;
  --ion-color-gold-rgb: 252, 182, 30;
  --ion-color-gold-contrast: #ffffff;
  --ion-color-gold-contrast-rgb: 255, 255, 255;
  --ion-color-gold-shade: #dea01a;
  --ion-color-gold-tint: #fcbd35;

  --ion-color-soft-gold: #e7bb6a;
  --ion-color-soft-gold-rgb: 231, 187, 106;
  --ion-color-soft-gold-contrast: #ffffff;
  --ion-color-soft-gold-contrast-rgb: 255, 255, 255;
  --ion-color-soft-gold-shade: #cba55d;
  --ion-color-soft-gold-tint: #e9c279;

  --ion-color-misty-moss: #bcab79;
  --ion-color-misty-moss-rgb: 188, 171, 121;
  --ion-color-misty-moss-contrast: #ffffff;
  --ion-color-misty-moss-contrast-rgb: 255, 255, 255;
  --ion-color-misty-moss-shade: #a5966a;
  --ion-color-misty-moss-tint: #c3b386;

  --ion-color-light-spring-bud: #b5bc69;
  --ion-color-light-spring-bud-rgb: 181, 188, 105;
  --ion-color-light-spring-bud-contrast: #ffffff;
  --ion-color-light-spring-bud-contrast-rgb: 255, 255, 255;
  --ion-color-light-spring-bud-shade: #9fa55c;
  --ion-color-light-spring-bud-tint: #bcc378;

  --ion-color-deep-spring-bud: #60712f;
  --ion-color-deep-spring-bud-rgb: 96, 113, 47;
  --ion-color-deep-spring-bud-contrast: #ffffff;
  --ion-color-deep-spring-bud-contrast-rgb: 255, 255, 255;
  --ion-color-deep-spring-bud-shade: #546329;
  --ion-color-deep-spring-bud-tint: #707f44;

  --ion-color-green: #008000;
  --ion-color-green-rgb: 0, 128, 0;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #007100;
  --ion-color-green-tint: #1a8d1a;

  --ion-color-light-green: #7acc7a;
  --ion-color-light-green-rgb: 122, 204, 122;
  --ion-color-light-green-contrast: #ffffff;
  --ion-color-light-green-contrast-rgb: 255, 255, 255;
  --ion-color-light-green-shade: #6bb46b;
  --ion-color-light-green-tint: #87d187;

  --ion-color-med-gray: #808080;
  --ion-color-med-gray-rgb: 128, 128, 128;
  --ion-color-med-gray-contrast: #ffffff;
  --ion-color-med-gray-contrast-rgb: 255, 255, 255;
  --ion-color-med-gray-shade: #717171;
  --ion-color-med-gray-tint: #8d8d8d;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-vendors: #679934;
  --ion-color-vendors-rgb: 103, 153, 52;
  --ion-color-vendors-contrast: #ffffff;
  --ion-color-vendors-contrast-rgb: 255, 255, 255;
  --ion-color-vendors-shade: #5b872e;
  --ion-color-vendors-tint: #76a348;

  --ion-color-offices: var(--ion-color-tufts-blue);
  --ion-color-offices-rgb: var(--ion-color-tufts-blue-rgb);
  --ion-color-offices-contrast: var(--ion-color-tufts-blue-contrast);
  --ion-color-offices-contrast-rgb: var(--ion-color-tufts-blue-contrast-rgb);
  --ion-color-offices-shade: var(--ion-color-tufts-blue-shade);
  --ion-color-offices-tint: var(--ion-color-tufts-blue-tint);

  --ion-color-appointments: var(--ion-color-tufts-blue-shaded);
  --ion-color-appointments-rgb: var(--ion-color-tufts-blue-shaded-rgb);
  --ion-color-appointments-contrast: var(--ion-color-tufts-blue-shaded-contrast);
  --ion-color-appointments-contrast-rgb: var(--ion-color-tufts-blue-shaded-contrast-rgb);
  --ion-color-appointments-shade: var(--ion-color-tufts-blue-shaded-shade);
  --ion-color-appointments-tint: var(--ion-color-tufts-blue-shaded-tint);
}

$colors: 'independence', 'blue', 'tufts-blue', 'tufts-blue-shaded', 'vivid-orange-peel', 'orange', 'lava', 'burgundy',
  'dark-green', 'dark-red', 'soft-red', 'yellow', 'bright-yellow', 'light-yellow', 'gold', 'soft-gold', 'misty-moss',
  'light-spring-bud', 'deep-spring-bud', 'green', 'light-green', 'med-gray', 'black', 'vendors', 'offices', 'appointments';

@each $color in $colors {
  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color});
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb);
    --ion-color-contrast: var(--ion-color-#{$color}-contrast);
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb);
    --ion-color-shade: var(--ion-color-#{$color}-shade);
    --ion-color-tint: var(--ion-color-#{$color}-tint);
  }
}
