//noinspection SpellCheckingInspection

@font-face {
  font-family: "DejaVu Sans Mono";
  src: url("/assets/font/DejaVuSansMono.woff") format("woff"),
  url("/assets/font/DejaVuSans.ttf") format("truetype")
}

.flex {
  display: flex;
}

.text-all-caps {
  text-transform: uppercase;
}

.text-title {
  text-transform: capitalize;
}

.text-lower {
  text-transform: lowercase;
}

.text-monospaced {
  font-family: 'DejaVu Sans Mono', monospace;
}

.text-smaller {
  font-size: smaller;
}

.text-small {
  font-size: small;
}

.text-large {
  font-size: large;
}

.text-larger {
  font-size: larger;
}

.text-center {
  text-align: center;
}

$utdirs: ('w': 'width', 'h': 'height');

@for $i from 1 through 4 {
  @each $char, $prop in $utdirs {
    .#{$char}-#{$i*25} {
      #{$prop}: percentage($i*25/100);
    }
  }
}

$utbox: ('m': 'margin', 'p': 'padding');

@for $i from 0 through 9 {
  @each $char, $prop in $utbox {
    .#{$char}-#{$i} {
      #{$prop}: #{$i}px !important;
    }


    .#{$char}y-#{$i} {
      #{$prop}-top: #{$i}px !important;
      #{$prop}-bottom: #{$i}px !important;
    }

    .#{$char}x-#{$i} {
      #{$prop}-left: #{$i}px !important;
      #{$prop}-right: #{$i}px !important;
    }


    .#{$char}t-#{$i} {
      #{$prop}-top: #{$i}px !important;
    }

    .#{$char}b-#{$i} {
      #{$prop}-bottom: #{$i}px !important;
    }

    .#{$char}l-#{$i} {
      #{$prop}-left: #{$i}px !important;
    }

    .#{$char}r-#{$i} {
      #{$prop}-right: #{$i}px !important;
    }
  }
}

@for $i from 1 through 5 {
  @each $char, $prop in $utbox {
    .#{$char}-#{$i*10} {
      #{$prop}: #{$i*10}px !important;
    }

    .#{$char}-#{$i*10+5} {
      #{$prop}: #{$i*10+5}px !important;
    }


    .#{$char}y-#{$i*10} {
      #{$prop}-top: #{$i*10}px !important;
      #{$prop}-bottom: #{$i*10}px !important;
    }

    .#{$char}x-#{$i*10} {
      #{$prop}-left: #{$i*10}px !important;
      #{$prop}-right: #{$i*10}px !important;
    }


    .#{$char}t-#{$i*10} {
      #{$prop}-top: #{$i*10}px !important;
    }

    .#{$char}b-#{$i*10} {
      #{$prop}-bottom: #{$i*10}px !important;
    }

    .#{$char}l-#{$i*10} {
      #{$prop}-left: #{$i*10}px !important;
    }

    .#{$char}r-#{$i*10} {
      #{$prop}-right: #{$i*10}px !important;
    }
  }
}

.heavy {
  font-weight: bold !important;
}

.light {
  font-weight: lighter !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-muted {
  color: var(--med-grey) !important;
}

.text-medium {
  color: var(--dark-grey) !important;
}

.text-light {
  color: var(--light-grey) !important;
}

.text-green {
  color: var(--green);
}

.text-yellow {
  color: var(--yellow);
}

.text-red {
  color: var(--red);
}

.text-dark-red {
  color: var(--dark-red);
}

.text-soft-red {
  color: var(--soft-red);
}
